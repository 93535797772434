import { useApi, useLazyApi } from './api';

export const useGetPracticeDetail = (publicToken) => {
	return useApi('GET', `/api/v1/gateway/${publicToken}`);
};

export const useConfirmPaymentHandler = (payload) => {
	return useLazyApi('POST', '/api/v1/gateway/confirm-payment', payload);
}

export const usePaymentResponseHandler = (payload) => {
	return useLazyApi('POST', '/api/v1/gateway/payment-response', payload);
}