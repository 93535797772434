import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { useGetPracticeDetail, useConfirmPaymentHandler } from '../store/rest';

import { isNil } from "lodash";

import NumberFormat from 'react-number-format';
import PaymentRequest from './form/paymentRequest';
import NexiPayment from './form/nexiPayment';

const PracticeDetail = () => {

    const { publicToken } = useParams();
    
    const [paymentConfirmed, setPaymentConfirmed] = useState(false);

    const { data: practiceData } = useGetPracticeDetail(publicToken);
    const [confirmPayment, { data: confirmPaymentData }] = useConfirmPaymentHandler();

    useEffect(() => {
        if (isNil(confirmPaymentData)) return;

        setPaymentConfirmed(true)
    }, [confirmPaymentData]);

    const handlePracticeDetail = () => {
        if (isNil(practiceData)) return null;

        return {
            firstname_by_client: practiceData.firstname,
            lastname_by_client: practiceData.lastname,
            email_by_client: practiceData.email,
            description_by_client: practiceData.description
        }
    }

    const confirm = (values) => {
        const payment = {
            id: practiceData.id,
            ...values
        }

        confirmPayment(payment)
    }

    const renderContent = () => {

        if (isNil(practiceData)) return null

        if (!paymentConfirmed) {
            
            if (practiceData.state !== 'NUOVA') 
                return <h5 className="text-center text-blue my-4">PAYMENT ALREADY ELABORATED</h5>

            return (
                <>
                    <h5 className="text-center text-blue my-4">PAYMENT DETAILS</h5>
                    <div className="row">
                        <div className="col">
                            <div className='form-group row' >
                                <label className='col-12 col-md-3 text-md-right text-blue'>Amount:</label>
                                <div className="col-12 col-md-8">
                                    <div className="form-control form-control-sm bg-gray">
                                        <NumberFormat value={practiceData.amount} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} suffix={` ${practiceData?.currency}`} />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <PaymentRequest onSubmit={confirm} initialValues={handlePracticeDetail()} />
                </>
            )
        }

        return (
            <>
                <h5 className="text-center text-blue my-4">RECAP</h5>
                <NexiPayment parameters={confirmPaymentData} />
                <div className="row">
                    <div className="col-12 text-center">
                        <button className="btn btn-blue rounded-0" onClick={() => setPaymentConfirmed(!paymentConfirmed)}>GO BACK</button>
                    </div>
                </div>
            </>
        )
    }

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12 col-md-10 offset-md-1">
                    <div className="card shadow-sm my-5">
                        <div className="card-body">
                            {renderContent()}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PracticeDetail;
