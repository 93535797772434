import React from "react";

import NumberFormat from 'react-number-format';

const NexiPayment = (props) => {

    const { alias, amount, codTrans, cognome, descrizione, divisa, email, importo, mac, nome, requestUrl, url, url_back } = props.parameters;

    return (
        <form method='POST' action={requestUrl}>
            <input type='hidden' name='alias' value={alias} />
            <input type='hidden' name='importo' value={importo} />
            <input type='hidden' name='divisa' value={divisa} />
            <input type='hidden' name='codTrans' value={codTrans} />
            <input type='hidden' name='url' value={url} />
            <input type='hidden' name='url_back' value={url_back} />
            <input type='hidden' name='mac' value={mac} />
            <input type='hidden' name='mail' value={email} />
            <input type='hidden' name='nome' value={nome} />
            <input type='hidden' name='cognome' value={cognome} />
            <input type='hidden' name='descrizione' value={descrizione} />
            <div className="row">
                <div className="col">
                    <div className='form-group row' >
                        <label className='col-12 col-md-3 text-blue text-md-right'>Amount:</label>
                        <div className="col-12 col-md-8">
                            <div className="form-control form-control-sm bg-gray">
                                <NumberFormat value={amount} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} suffix={` ${divisa}`} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className='form-group row' >
                        <label className='col-12 col-md-3 text-blue text-md-right'>Firstname:</label>
                        <div className="col-12 col-md-8">
                            <div className="form-control form-control-sm bg-gray">
                                {nome}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className='form-group row' >
                        <label className='col-12 col-md-3 text-blue text-md-right'>Lastname:</label>
                        <div className="col-12 col-md-8">
                            <div className="form-control form-control-sm bg-gray">
                                {cognome}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className='form-group row' >
                        <label className='col-12 col-md-3 text-blue text-md-right'>Email:</label>
                        <div className="col-12 col-md-8">
                            <div className="form-control form-control-sm bg-gray">
                                {email}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className='form-group row' >
                        <label className='col-12 col-md-3 text-blue text-md-right'>Description:</label>
                        <div className="col-12 col-md-8">
                            <textarea className="form-control form-control-sm bg-gray" rows="3">
                                {descrizione}
                            </textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12 text-center">
                    <button type="submit" className="btn btn-blue rounded-0 my-3">GO TO PAYMENT PAGE</button>
                </div>
            </div>
        </form>
    );
};

export default NexiPayment;
