import React from "react";

import { Switch, Route } from 'react-router-dom';

import Layout from "./containers/layout";
import Payment from "./pages/payment";
import Response from "./pages/response";
import Homepage from "./pages/homepage";

const App = () => {

  return (
      <Layout>
          <Switch>
            <Route path="/payment-request/:publicToken" exact render={(props) => <Payment {...props} />} />
            <Route path="/payment-response/:publicToken/:type" exact render={(props) => <Response {...props} />} />
            <Route component={Homepage} />
          </Switch>
      </Layout>
  );

}

export default App;
