import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';

import {getToken} from "../store/actions"

import { isNil } from "lodash";

import Spinner from "../components/ui/spinner"
import PracticeDetail from "../components/practiceDetail"

const Payment = () => {

    const dispatch = useDispatch();

    const { publicToken } = useParams();

    const { token } = useSelector((state) => state.app);

    useEffect(() => {
		if (isNil(publicToken) || !isNil(token)) return;

		dispatch(getToken(publicToken));
	}, []);   

    if (isNil(publicToken) || isNil(token)) return <Spinner />

    return <PracticeDetail />
};

export default Payment;
