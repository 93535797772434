import React from "react";

const Homepage = () => {
    return (
        <div className="container h-100">
            <div className="row align-items-center h-100">
                <div className="col-sm-12 col-lg-8 mx-auto">
                    <div className="jumbotron text-center bg-white">
                        <h1 className="display-4">The Travel Expert</h1>
                        <hr className="my-4" />
                        <p className="lead">Pagaonline</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Homepage;
