import React from "react";

import { Field, reduxForm } from "redux-form";

import { renderInputField, renderTextArea } from "./render";
import { required, email } from "./validate"

const PaymentRequest = (props) => {

    const { handleSubmit, submitting } = props;

    return (
        <form onSubmit={handleSubmit}>
            <div className="row">
                <div className="col">
                    <Field formName="paymentRequest" name="firstname_by_client" label="*Firstname" required validate={required} component={renderInputField} renderInline />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <Field formName="paymentRequest" name="lastname_by_client" label="*Lastname" required validate={required} component={renderInputField} renderInline />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <Field formName="paymentRequest" name="email_by_client" label="*Email" required validate={email} component={renderInputField} renderInline />
                </div>
            </div>
            <div className="row">
                <div className="col-12 my-2">
                    <Field formName="paymentRequest" name="description_by_client" label="*Description" rows="3" required validate={required} component={renderTextArea} renderInline />
                </div>
            </div>
            <div className="row">
                <div className="col-12 text-center">
                    <button type="submit" className="btn btn-blue rounded-0 my-3" disabled={submitting} >CONFIRM</button>
                </div>
            </div>
        </form >
    );
};

export default reduxForm({
    form: "paymentRequest",
})(PaymentRequest);
