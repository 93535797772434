import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';

import { getToken } from "../store/actions"
import { usePaymentResponseHandler } from '../store/rest';

import { isNil } from "lodash";

import Spinner from "../components/ui/spinner"

const Response = (props) => {

    const dispatch = useDispatch();
    const [paymentResult, setPaymentResult] = useState(null);

    const { publicToken, type } = useParams();
    const { search } = props.location
    const { token } = useSelector((state) => state.app);

    const [paymentResponse, { data: paymentResponseData }] = usePaymentResponseHandler();

    useEffect(() => {
        if (isNil(publicToken) || !isNil(token)) return;

        dispatch(getToken(publicToken));
    }, []);

    useEffect(() => {
        if (isNil(token)) return;

        paymentResponse({
            public_token: publicToken,
            type: type,
            params: search
        })

    }, [token])

    useEffect(() => {
        if (isNil(paymentResponseData)) return;

        setPaymentResult(paymentResponseData.value)
    }, [paymentResponseData])


    if (isNil(publicToken) || isNil(token) || isNil(paymentResult)) return <Spinner />

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12 col-md-6 offset-md-3">
                    <div className="card shadow-sm my-5">
                        <div className="card-body text-center">
                            <strong>Payment result:</strong> {paymentResult}.
                        </div>
                        <div className="card-footer text-center">
                            You can close this page
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Response;