import { createStore, combineReducers, applyMiddleware } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import appReducers from './reducers';
import { reducer as formReducer } from "redux-form";

const combinedReducers = combineReducers({
  ...appReducers,
  form: formReducer
});

const store = createStore(combinedReducers, applyMiddleware(thunk, logger));

export { store };
