import * as actions from './types';

const initial = {
	app: {
		token: null,
		modal: false
	}
};

const app = (state = initial.app, action) => {
	switch (action.type) {
		case actions.BOOT: {
			return {
				...state,
				token: action.payload.token
			};
		}
		case actions.MODAL: {
			return {
				...state,
				modal: action.payload
			};
		}
		default:
			return state;
	}
};

export default { app };
