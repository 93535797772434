import * as actions from './types';
import { isNil } from 'lodash';
import { useRawCall } from './api';

export const getToken = (publicToken) => {
	return async (dispatch) => {
		const response = await useRawCall('GET', `/auth/api/v1/gateway/${publicToken}`);

		const { data, error } = response;

		if (!isNil(data) && isNil(error)) {
			dispatch({
				type: actions.BOOT,
				payload: { token: data }
			});
		}

		return Promise.resolve();
	};
};


export const handleModal = (isVisible) => {
	return async (dispatch) => {

		dispatch({
			type: actions.MODAL,
			payload: isVisible
		});

	};
}
