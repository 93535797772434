import React from "react";

const Footer = () => {

    return (
        <footer className="footer">
            The Travel Expert - gateway di pagamento
        </footer>
    );
};

export default Footer;